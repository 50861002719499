<template>
<div id="tab-container">
  <b-tabs pills content-class="mt-3 text-center" align="center">
    <b-tab title="Kierowcy we flocie" active>
      <b-button variant="primary" class="float-right my-4" v-b-modal.add-emp>Dodaj kierowcę</b-button>
      <b-table
        v-if="!isLoading && returnDrivers"
        hover
        striped
        class="vehtable"
        :items="returnDrivers"
        :sort-by.sync="sortBy"
        :fields="fields"
        responsive
      >
        <template #cell(active)="row">
          {{ row.item.active ? "Tak" : "nie" }}
        </template>
        <template #cell(edycja)="row">
          <b-button
            size="sm"
            variant="primary"
            @click="toggleDriver(row.item)"
            class="mr-1"
          >
            Edytuj
          </b-button>
          <b-button
            v-if="row.item.active"
            size="sm"
            variant="warning"
            @click=""
            class="mr-1"
          >
            Dezaktywuj
          </b-button>
          <b-button
            v-else
            size="sm"
            variant="primary"
            @click="toggleDriver(row.item)"
            class="mr-1"
          >
            Aktywuj
          </b-button>
          <b-button
            size="sm"
            variant="danger"
            @click="deleteEmp(row.item.id)"
            class="mr-1"
          >
            Usuń
          </b-button>
        </template>
      </b-table>
      <h5 v-if="!returnDrivers.length">
        Nikt z kierowców nie przesłał jeszcze grafiku - dlatego nie jest aktualnie wyświetlana lista. Kierowcy dodawani są automatyczne.
      </h5>
    </b-tab>
  </b-tabs>
  <new-emp @refresh="fetchData" />
</div>
</template>

<script>
import NewEmp from '../components/modals/NewEmp.vue';
export default {
  components: {
    NewEmp
  },
  data() {
    return {
      isLoading: false,
      sortBy: "fullName",
      fields: [
        {
          key: "fullName",
          label: "Imię i nazwisko",
          sortable: true,
        },
        {
          key: "phoneNumber",
          label: "Numer telefonu",
          sortable: true,
        },
        {
          key: "pinCode",
          label: "Kod PIN",
          sortable: true,
        },
        {
          key: "badania",
          label: "Termin badań",
          sortable: true,
        },
        {
          key: "active",
          label: "Aktywny",
          sortable: true,
        },
        {
          key: "edycja",
          label: "Edycja",
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch("getDrivers");
      this.isLoading = false;
    },

    async deleteEmp(id) {
      await this.$store.dispatch("removeDriverList", id);
    },
    async toggleDriver(item) {
      this.$store.dispatch("toggleDriver", {
        active: !item.active,
        id: item.id,
      });
    },
  },
  computed: {
    returnDrivers() {
      return !this.isLoading && this.$store.getters['getDriverList'];
    },
  },
};
</script>

<style scoped>
#tab-container {
  width: 95%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
}
.vehtable {
  text-align: center;
}
</style>
