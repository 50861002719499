<template>
  <div class="pb-5">
    <div class="dark text-center">
      <h1>System kontroli paliwa</h1>
      <img
        class="mt-2"
        src="../assets/cabgo.png"
        alt=""
        srcset=""
      />
    </div>
    <div class="container" v-if="returnValidatedUser">
      <div class="mt-5">
        <h2>Wybierz dzień</h2>
        <b-form-datepicker
          :start-weekday="1"
          v-model="date"
          class="mb-2"
          placeholder="Wybierz datę"
        />
        <b-form-input
          autocomplete="off"
          type="number"
          class="mb-2"
          placeholder="Cena benzyny za litr"
          v-model.number="fuelPrice"
        />
        <b-form-input
          autocomplete="off"
          type="number"
          class="mb-2"
          placeholder="Cena gazu za litr"
          v-model.number="gasPrice"
        />
        <b-button
          class="mt-2 float-right"
          :class="date && gasPrice ? 'ready' : ''"
          variant="dark"
          @click="fetchFuelWeek"
          :disabled="!date"
          >Pobierz dane</b-button
        >
      </div>
      <div class="fuel-list" v-if="returnSelectedDay">
        <b-row>
          <b-col md="6"
            ><h3>Dzień</h3>
            <transition-group tag="div" name="list">
              <div
                v-for="car in returnSelectedDay.singleDay.cars"
                v-if="
                  car.drivers.length && !hiddenItems.includes(car.drivers[0].id)
                "
                :key="car.drivers[0].id"
              >
                <b-input-group
                  class="mb-2 mt-4"
                  :class="
                    car.drivers[0].gaz && car.drivers[0].godziny ? 'done' : ''
                  "
                >
                  <b-input-group-prepend is-text>
                    {{ car.drivers[0].fullName }}
                  </b-input-group-prepend>
<!-- 
                  <b-form-input
                    autocomplete="off"
                    type="number"
                    placeholder="work h"
                    v-model.number="car.drivers[0].godziny"
                  /> -->
                </b-input-group>
                <b-input-group
                  class="mb-2"
                  :class="car.drivers[0].gaz ? 'done' : ''"
                >
                  <b-form-input
                    autocomplete="off"
                    placeholder="ilość l b"
                    v-model.number="car.drivers[0].benzyna"
                  />
                  <b-form-input
                    autocomplete="off"
                    placeholder="Ilość l g"
                    v-model.number="car.drivers[0].gaz"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-success"
                      v-if="car.drivers[0].gaz"
                      @click="hide('days', car.drivers[0].id)"
                      ><b-icon icon="check-lg"></b-icon
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
                <h5 v-if="car.drivers[0].gaz && gasPrice">
                  Koszt paliwa:
                  {{
                    (car.drivers[0].benzyna > 0
                      ? car.drivers[0].benzyna * fuelPrice
                      : 0 + car.drivers[0].gaz * gasPrice
                    ).toFixed(2)
                  }}
                  zł
                </h5>
                <strong>{{ car.reg }}</strong>
                <b-alert
                  variant="success"
                  :show="isAlreadySubmitted(car.drivers[0].phone)"
                  >Wysłane</b-alert
                >
                <hr />
              </div>
            </transition-group>
            <b-button
              variant="outline-success"
              @click="postForm('dzień')"
              @touchend="postForm('dzień')"
              v-if="!isSubbmiting"
              class="float-right"
              >Zapisz dzienne zmiany</b-button
            >
          </b-col>
          <b-col md="6"
            ><h3>Noc</h3>
            <transition-group tag="div" name="list">
              <div
                v-for="car in returnSelectedDay.singleNight.cars"
                v-if="
                  car.drivers.length && !hiddenItems.includes(car.drivers[0].id)
                "
                :key="car.drivers[0].id"
              >
                <b-input-group
                  class="mb-2 mt-4"
                  :class="
                    car.drivers[0].gaz && car.drivers[0].godziny ? 'done' : ''
                  "
                >
                  <b-input-group-prepend is-text>
                    {{ car.drivers[0].fullName }}
                  </b-input-group-prepend>

                  <!-- <b-form-input
                    autocomplete="off"
                    placeholder="work h"
                    v-model.number="car.drivers[0].godziny"
                  /> -->
                </b-input-group>
                <b-input-group
                  class="mb-2"
                  :class="car.drivers[0].gaz ? 'done' : ''"
                >
                  <b-form-input
                    autocomplete="off"
                    placeholder="ilość l b"
                    v-model.number="car.drivers[0].benzyna"
                  />
                  <b-form-input
                    autocomplete="off"
                    placeholder="Ilość l g"
                    v-model.number="car.drivers[0].gaz"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-success"
                      v-if="car.drivers[0].gaz"
                      @click="hide('nights', car.drivers[0].id)"
                      ><b-icon icon="check-lg"></b-icon
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
                <h5 v-if="car.drivers[0].gaz && gasPrice">
                  Koszt paliwa:
                  {{
                    (car.drivers[0].benzyna > 0
                      ? car.drivers[0].benzyna * fuelPrice
                      : 0 + car.drivers[0].gaz * gasPrice
                    ).toFixed(2)
                  }}
                  zł
                </h5>
                <strong>{{ car.reg }}</strong>
                <b-alert
                  variant="success"
                  :show="isAlreadySubmitted(car.drivers[0].phone)"
                  >Wysłane</b-alert
                >
                <hr />
              </div>
            </transition-group>
            <b-button
              v-if="!isSubbmiting"
              variant="outline-success"
              @click="postForm('noc')"
              @touchend="postForm('noc')"
              class="float-right"
              >Zapisz nocne zmiany</b-button
            >
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal
      id="password-check"
      ref="modal"
      title="Aby uzyskać dostęp wprowadź dane"
      cancel-title="Anuluj"
      @ok="handleSubmit"
      centered
    >
      <b-alert variant="danger" :show="isError"
        >Wprowadziłeś niepoprawne hasło.</b-alert
      >
      <form ref="form" @submit.prevent>
        <b-form-group
          label="Hasło dostępowe"
          label-for="name-input"
          invalid-feedback="Wprowadź hasło"
        >
          <b-form-input id="name-input" v-model="pass"></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: null,
      isAuthenticated: false,
      pass: null,
      isError: false,
      isLoading: false,
      gasPrice: null,
      fuelPrice: null,
      hiddenItems: [],
      isSubbmiting: false,
    };
  },

  mounted() {
    this.$refs["modal"].show();
  },
  methods: {
    async handleSubmit(event) {
      event.preventDefault();
      this.isLoading = true;
      await this.$store
        .dispatch("checkFuelPIN", { password: this.pass })
        .then((res) => {
          this.$refs["modal"].hide();
        })
        .catch((err) => {
          this.isError = true;
        });
      this.isLoading = false;
    },
    async fetchFuelWeek() {
      this.isLoading = true;
      await this.$store.dispatch("fetchFuelWeek", {
        week: this.getWeekAndYearNumber.week,
        year: this.getWeekAndYearNumber.year,
        pass: this.pass,
      });
      await this.$store.dispatch("fetchAlreadySaved", {
        date: this.date,
        pass: this.pass,
      });
      this.isLoading = false;
    },
    hide(shift, id) {
      if (shift === "days") {
        this.returnSelectedDay.singleDay.cars.forEach((el) => {
          const lookForDriver = el.drivers.find((driver) => driver.id === id);
          if (lookForDriver) {
            this.hiddenItems.push(id);
          }
        });
      } else {
        this.returnSelectedDay.singleNight.cars.forEach((el) => {
          const lookForDriver = el.drivers.find((driver) => driver.id === id);
          if (lookForDriver) {
            this.hiddenItems.push(id);
          }
        });
      }
    },
    async postForm(shiftMode) {
      this.isSubbmiting = true;
      const day = this.date;
      const { singleDay, singleNight } = this.returnSelectedDay;
      const details = shiftMode === "dzień" ? singleDay : singleNight;
      await this.$store.dispatch("addDayFuel", {
        day,
        shift: shiftMode,
        details,
        pass: this.pass,
        gasPrice: this.gasPrice,
        fuelPrice: this.fuelPrice,
      });
      this.isSubbmiting = false;
    },
    isAlreadySubmitted(phoneNumber) {
      return this.getSavedFuel.find(
        (el) => el.attributes.phoneNumber === phoneNumber
      )
        ? true
        : false;
    },
  },
  computed: {
    returnValidatedUser() {
      return !this.isLoading && this.$store.getters["getFuelEmp"];
    },
    getSavedFuel() {
      return !this.isLoading && this.$store.getters["returnSavedDayFuel"];
    },
    getWeekAndYearNumber() {
      // Convert the input date string to a Date object
      const inputDate = new Date(this.date + "T00:00:00Z");

      const year = inputDate.getFullYear();

      const week = this.$dayjs(inputDate).week();
      // Calculate the ISO week number
      return this.date && { week, year };
    },
    returnDayAndMonth() {
      const date = new Date(this.date);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      return this.date && { day, month };
    },
    returnSelectedDay() {
      const fuelWeek = this.$store.getters["getFuelWeek"];
      if (this.date && fuelWeek) {
        const { day, month } = this.returnDayAndMonth || null;
        const singleDay = fuelWeek.days.find(
          (el) => el.day === day && el.month === month
        );
        const singleNight = fuelWeek.nights.find(
          (el) => el.day === day && el.month === month
        );
        return !this.isLoading && { singleDay, singleNight };
      }
      return null;
    },
    isAllCompleted() {
      this.returnSelectedDay.singleDay.forEach((el) => {});
    },
  },
  watch: {
    async date(val) {
      this.isLoading = true;
      await this.$store.dispatch("fetchFuelWeek", {
        week: this.getWeekAndYearNumber.week,
        year: this.getWeekAndYearNumber.year,
        pass: this.pass,
      });
      await this.$store.dispatch("fetchAlreadySaved", {
        date: this.date,
        pass: this.pass,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.dark {
  background-color: #000;
  color: #fff;
  padding: 32px;
}
.fuel-list {
  margin-top: 80px;
}

.ready {
  background-color: #f04c1c;
  border-color: #f04c1c;
}

input {
  text-align: center;
}

.done input {
  background: #8ee488;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(-50%);
}
</style>
